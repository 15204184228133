@import "./fonts/tripper-icons/style.scss";
@import "~react-responsive-carousel/lib/styles/carousel.min.css";
@import "~bulma/sass/utilities/all";
@import "~bulma/sass/base/all";
@import "~bulma/sass/components/level";
@import "~bulma/sass/components/dropdown";
@import "~bulma-switch";
@import "~bulma/sass/grid/all";
@import "~bulma/sass/helpers/all";

strong {
    color: inherit;
}


body {
  --base-background-color: #f6f5fe !important;
  --itinerary-background-color: #f6f5fe !important;

  --app-header--gradient: linear-gradient(90deg, #0076B8 0%, #032545 100%);

  //Style the color of the X
  --itinerary-header--text-color: black;
  --itinerary-header--background-color: #ffffff;
  --itinerary-header--border: 1px solid #000000;
  --itinerary-header--box-shadow: 0px 2px 8px 0px #1C1B5833;
  --atlas-header--icon-color: white;

  --metadata--text-color: #414141 !important;
  --metadata--label-color: #414141 !important;
  --metadata--background-top-gradient-start: #ffffff !important;
  --metadata--background-top-gradient-end: #ffffff !important;
  --metadata--background-bottom--color: none !important;
  --metadata--route-name--color: #414141 !important;
  --metadata--itinerary-name--color: #000000 !important;
  --metadata-tags--background-color: rgba(0, 104, 255, 0.67) !important;
  --metadata-tags--label-color: white !important;
  --metadata-icons--color: #6296f6 !important;
  --metadata-description--text-color: #000000 !important;
  --metadata--link-color: blue !important;
  --metadata-button--text-color: blue !important;
  --metadata-button--border-color: 1px solid rgba(0, 0, 0, 0.6);
  --metadata-button--background-color--hover: rgba(255, 255, 255, 0.1);
  --metadata-button--background-color--active: rgba(175, 175, 175, 0.76);
  --metadata-button-counter-clockwise--border-color: 1px solid rgba(0, 0, 0, 0.76);
  --metadata--bottom-border: 1px solid rgba(171, 171, 171, 0.6);

  --itineraryStartEnd-card--background-color: rgb(236, 235, 244) !important;
  --itineraryStartEnd-card--text-color: black !important;
  --itineraryStartEnd-card--border: 1px solid darkgray !important;
  --itineraryStartEnd-card--link--color: blue !important;

  --stop-card--background--color: white !important;
  --stop-card--border: 1px solid lightgray !important;

  --text-title--color: black !important;
  --text-address--color: darkgray !important;
  --text-description--color: black !important;
  --text-description-link--color: blue !important;

  --border-color: #f1f1f1 !important;

  --button-border--color: lightgray !important;
  --button-background--color: lightgray !important;
  --button-background--color-active: black !important;
  --button-background--color-hover: black !important;

  --button-text--color: lightgray !important;
  --button-text--color-hover: white !important;
  --button-text--color-active: white !important;

  --button-border-atlas--color: green !important;
  --button-background-atlas--color: #4FBA !important;
  --button-background-atlas--color-active: black !important;
  --button-background-atlas--color-hover: black !important;

  --button-text-atlas--color: black !important;
  --button-text-atlas--color-hover: white !important;
  --button-text-atlas--color-active: white !important;

  --tag-text--color: #ffffff !important;
  --tag-text--color-active: #ffffff !important;
  --tag-background--color: #b9b9b9 !important;
  --tag-background--color-active: #00a6ff !important;

  //Markers on itineraries are different on desktop/mobile. Not on atlases
  --marker-text--color--mobile: white !important;
  --marker-background--color--mobile: black !important;
  --marker-border--mobile: 1px solid #8a8a8a !important;
  --marker-box-shadow--mobile: 1px 1px 1px rgba(0, 0, 0, 0.15);
  --marker-text--color--desktop: black !important;
  --marker-background--color--desktop: white !important;
  --marker-border--color--desktop: 1px solid #8a8a8a !important;
  --marker-box-shadow--desktop: 1px 1px 2px rgba(0, 0, 0, 0.5);
  --marker-background--color--hover: #00a6ff !important;
  --marker-border--color--hover: black !important;
  --marker-text--color--hover: white !important;
  --marker-background--color--desktop--hover: black !important;
  --marker-inPlace--background--color: green !important;
  --marker-inPlace--border-color: green !important;
  --marker-inPlace--text--color: green !important;

  --tween-background--color: white !important;
  --tween-text--color: #505050 !important;
  --tween-link--color: #002aff !important;

  --footer-text--color: darkGray !important;
  --footer-background--color: #d5d5d5 !important;

}